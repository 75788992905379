import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import * as classes from './indexHero.module.scss';

export function IndexHero({ content }) {
   return (
      <Container className="full-height">
         <Row className="text-center align-items-bottom full-height">
            <Col md="12" lg="11" xl="10" className="m-auto">
               <div className={classes.contentContainer}>
                  <div dangerouslySetInnerHTML={{ __html: content.html }} />
               </div>
            </Col>
            <Col xs="12" className="d-none d-md-block">
               <div className={classes.ioDashboardContainer}>
                  <div className={classes.ioDashboard} />
                  <div className={classes.ioDashboardOverlay} />
               </div>
            </Col>
         </Row>
      </Container>
   );
}
