import React from 'react';

import { Container } from 'react-bootstrap';
import { HeadElement } from '../components/global/HeadElement';
import { graphql } from 'gatsby';

import { FlowToContact } from '../components/global/FlowToContact';
import { IndexContent } from '../components/index/IndexContent';
import { IndexHero } from '../components/index/IndexHero';

function Index({ data }) {
   const { contentHero, contentHeader } = data;

   return (
      <>
         <HeadElement namespace="index" />
         <IndexHero content={contentHero} />
         <IndexContent header={contentHeader} />
         <Container>
            <FlowToContact variant="vture-dark-cyan" />
         </Container>
      </>
   );
}

export default Index;

export const query = graphql`
   query ($language: String!) {
      locales: allLocale(filter: { language: { eq: $language } }) {
         edges {
            node {
               ns
               data
               language
            }
         }
      }
      contentHero: markdownRemark(frontmatter: { route: { eq: "/index" }, language: { eq: $language }, isHero: { eq: true } }) {
         html
         frontmatter {
            language
         }
      }
      contentHeader: markdownRemark(frontmatter: { route: { eq: "/index" }, language: { eq: $language }, isHeader: { eq: true } }) {
         html
         frontmatter {
            language
         }
      }
   }
`;
