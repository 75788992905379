import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Parallax } from 'rc-scroll-anim';
import { AnchorLink } from './AnchorLink';

export function ScrollDownIndicator({ scrollTo }) {
   return (
      <div className="text-center scroll-down-indicator">
         <Parallax animation={[{ opacity: 0 }]} style={{ opacity: 0.6 }}>
            <AnchorLink href={scrollTo} offset={10}>
               <FontAwesomeIcon icon="angle-down" size="3x" />
            </AnchorLink>
         </Parallax>
      </div>
   );
}
