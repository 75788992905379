import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export function HeadElement({ namespace = '' }) {
   const [t] = useTranslation(namespace);

   return (
      <Helmet>
         <title>{t('meta.title')}</title>
         <meta name="description" content={t('meta.description')} />
         <meta name="keywords" content={t('meta.keywords')} />
         <meta name="theme-color" content="#07131a" />
      </Helmet>
   );
}
