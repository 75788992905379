import React from 'react';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { Parallax } from 'rc-scroll-anim';
import { Button, Col, Container, Row } from 'react-bootstrap';

import playIcon from '../../images/play_icon.svg';
import transmitArrow from '../../images/transmit_arrow.svg';

import * as classes from './indexContent.module.scss';
import { SectionHeader } from '../global/SectionHeader';
import { ScrollDownIndicator } from '../helper/ScrollDownIndicator';
import classNames from 'classnames';

export function IndexContent({ header }) {
   const { t } = useTranslation('welcome');

   const headerHtml = <div dangerouslySetInnerHTML={{ __html: header.html }}></div>;

   return (
      <>
         <section id="1">
            <div className="dark-background">
               <div className="d-none d-lg-block">
                  <ScrollDownIndicator scrollTo="#1" />
               </div>
               <div className="pt-2 d-none d-md-block" />
               <Container>
                  <SectionHeader content={headerHtml} />
                  <Row>
                     <Col xs="12" lg="11" xl="10">
                        <Parallax animation={{ opacity: 1, playScale: [0, 0.45] }} style={{ opacity: 0 }}>
                           <div className="ingest">
                              <div className={classes.contentContainerIngest}>
                                 <h2>INGEST</h2>
                                 <Row>
                                    <Col xs={12} md={9} lg={8} xl="6">
                                       <h1>
                                          <span className="text-bold">{t('ingest.title1')}</span> {t('ingest.title2')}
                                       </h1>
                                    </Col>
                                 </Row>
                              </div>
                              <div className="d-none d-md-block">
                                 <Parallax
                                    animation={{ x: 0, opacity: 1, playScale: [0.1, 0.6] }}
                                    style={{ transform: 'translateX(60px)', opacity: 0 }}
                                 >
                                    <div className={classNames(classes.ingestPixels, classes.ingestPixels1)} />
                                 </Parallax>
                                 <Parallax
                                    animation={{ x: 0, opacity: 1, playScale: [0.15, 0.5] }}
                                    style={{ transform: 'translateX(-50px)', opacity: 0 }}
                                 >
                                    <div className={classNames(classes.ingestPixels, classes.ingestPixels2)} />
                                 </Parallax>
                                 <Parallax
                                    animation={{ x: 0, opacity: 1, playScale: [0.2, 0.6] }}
                                    style={{ transform: 'translateX(-35px)', opacity: 0 }}
                                 >
                                    <div className={classNames(classes.ingestPixels, classes.ingestPixels3)} />
                                 </Parallax>
                              </div>
                           </div>
                        </Parallax>
                     </Col>
                  </Row>
                  <Row>
                     <Col xs={12} md={7}>
                        <div className={classes.contentContainer}>
                           <p className="text-vture-yellow mt-3 mb-3">{t('ingest.text')}</p>
                           <Link to="/ingest">
                              <Button size="lg" variant="outline-vture-yellow">
                                 {t('ingest.button')}
                              </Button>
                           </Link>
                        </div>
                     </Col>
                     <Col></Col>
                  </Row>
               </Container>
               <div className="pb-5 d-none d-md-block" />
            </div>
         </section>

         <section id="transmit">
            <div className="dark-background">
               <div className="pt-5 d-none d-md-block" />
               <Container>
                  <Row className="pt-5">
                     <Col md="3" sm="0"></Col>
                     <Col sm="12" md="6" lg="6" xl="6">
                        <Parallax animation={{ opacity: 1, playScale: [0, 0.45] }} style={{ opacity: 0 }}>
                           <div className="transmit">
                              <div className="d-none d-md-block">
                                 <Parallax
                                    animation={{ x: 0, opacity: 1, playScale: [0.3, 0.65] }}
                                    style={{ transform: 'translateX(100px)', opacity: 0 }}
                                 >
                                    <div className={classes.transmitArrowLeft}>
                                       <img src={transmitArrow} alt="" height={110} />
                                    </div>
                                 </Parallax>
                                 <Parallax
                                    animation={{ x: 0, opacity: 1, playScale: [0.2, 0.7] }}
                                    style={{ transform: 'translateX(-120px)', opacity: 0 }}
                                 >
                                    <div className={classes.transmitArrowRight}>
                                       <img src={transmitArrow} alt="" height={110} />
                                    </div>
                                 </Parallax>
                                 <Parallax animation={{ opacity: 1, playScale: [0.2, 0.5] }} style={{ transform: 'translateY(300px)', opacity: 0 }}>
                                    <div className={classes.transmitArrowBottom} />
                                 </Parallax>
                              </div>
                              <div className={classes.contentContainerTransmit}>
                                 <h2>TRANSMIT</h2>
                                 <Row>
                                    <Col xs="10">
                                       <h1 className="text-vture-blue">
                                          <span className="text-bold">{t('transmit.title1')}</span> {t('transmit.title2')}
                                       </h1>
                                    </Col>
                                 </Row>
                              </div>
                           </div>
                        </Parallax>
                     </Col>
                  </Row>
                  <Row>
                     <Col xs={12} md={7}>
                        <div className={classes.contentContainer}>
                           <p className="text-vture-light-cyan mt-3 mb-3">{t('transmit.text')}</p>
                           <Link to="/transmit">
                              <Button size="lg" variant="outline-vture-light-cyan">
                                 {t('transmit.button')}
                              </Button>
                           </Link>
                        </div>
                     </Col>
                     <Col></Col>
                  </Row>
               </Container>
               <div className="pb-5 d-none d-md-block" />
            </div>
         </section>

         <section id="playout">
            <div className="dark-background">
               <div className="pt-5 d-none d-md-block" />
               <Container>
                  <Parallax animation={{ opacity: 1, playScale: [0.1, 0.5] }} style={{ opacity: 0 }}>
                     <Row className="py-5">
                        <Col sm="12" md="7">
                           <div className={classes.contentContainerPlayout}>
                              <h2>PLAYOUT</h2>
                              <Row>
                                 <Col xs="10">
                                    <h1 className="text-vture-blue pb-3">
                                       <span className="text-bold">{t('playout.title1')}</span> {t('playout.title2')}
                                    </h1>
                                 </Col>
                              </Row>
                           </div>
                        </Col>
                        <Col xs={12} md={5}>
                           <div className="d-none d-md-block">
                              <div className={classes.playIcons}>
                                 <Parallax
                                    animation={{ x: 0, opacity: 1, playScale: [0.15, 0.6] }}
                                    style={{ transform: 'translateX(50px)', opacity: 0 }}
                                 >
                                    <div className={classNames(classes.playIcon, classes.playIcon1)}>
                                       <img src={playIcon} alt="" height={150} />
                                    </div>
                                 </Parallax>
                                 <Parallax animation={{ x: 0, opacity: 1, playScale: [0.2, 0.6] }} style={{ opacity: 0 }}>
                                    <div className={classNames(classes.playIcon, classes.playIcon2)}>
                                       <img src={playIcon} alt="" height={150} />
                                    </div>
                                 </Parallax>
                                 <Parallax
                                    animation={{ x: 0, opacity: 1, playScale: [0.15, 0.6] }}
                                    style={{ transform: 'translateX(-50px)', opacity: 0 }}
                                 >
                                    <div className={classNames(classes.playIcon, classes.playIcon3)}>
                                       <img src={playIcon} alt="" height={150} />
                                    </div>
                                 </Parallax>
                              </div>
                           </div>
                           <div className={classes.contentContainer}>
                              <p className="text-vture-orange mt-3 mb-3">{t('playout.text')}</p>
                              <Link to="/playout">
                                 <Button size="lg" variant="outline-vture-orange">
                                    {t('playout.button')}
                                 </Button>
                              </Link>
                           </div>
                        </Col>
                     </Row>
                  </Parallax>
               </Container>
               <div className="pb-5 d-none d-md-block" />
            </div>
         </section>
      </>
   );
}
